import React from "react"
import { Col, Row } from "react-bootstrap"
import LazyLoad from "react-lazyload"
import Slide from "../utility/slide_content"

const angelInvestors = ({ data }) => {
  let orderedData = data.sort((a, b) => a.order - b.order)
  return (
    <Row>
      {orderedData.map((people, i) => {
        const { name, title, picture } = people
        return (
          <Col lg={4} sm={6} className="d-flex" key={i}>
            <Slide delay="200">
              <div className="angel-investors-card">
                <div className="angel-investors-image">
                  <LazyLoad throttle={200} height={200}>
                    <img src={picture.url} alt={name} />
                  </LazyLoad>
                </div>

                <div className="info">
                  <h6 className="fw-bold">{name}</h6>
                  <div className="p14">
                    <p> {title} </p>
                  </div>
                </div>
              </div>
            </Slide>
          </Col>
        )
      })}
    </Row>
  )
}

export default angelInvestors
