import { graphql, Link, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import AngelInvestors from "../components/about_us/angel_investors"
import SeedInvestors from "../components/about_us/seed_investors"
import CTA from "../components/common/CTA"
import Frame from "../components/common/frame"
import SVGIcon from "../components/common/SVGIcon"
import Navigation from "../components/navigation"
import Slide from "../components/utility/slide_content"
import "../styles/pages/about-us.scss"

function AboutUs() {
  const FoundersCard = props => {
    return props.data.map((el, idx) => {
      return (
        <div className="founder-card">
          <div className="card-wapper">
            <div>
              <img src={el.picture.url} alt="" className="founder-image" />
            </div>
            <div className="card-body">
              <p className="name">{el.name}</p>
              <div className="d-flex justify-content-between">
                <p className="role">{el.title}</p>

                <div className="social">
                  <div>
                    <Link to={el.twitterUrl} target="_blank">
                      <SVGIcon name="social/icontwitter" />
                    </Link>
                  </div>

                  <div>
                    <Link to={el.linkedInUrl} target="_blank">
                      <SVGIcon name="social/iconlinkedin" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <StaticQuery
        query={graphql`
          query contentAboutUs {
            SuperOps {
              pages(where: { title: "About us" }) {
                title
                pageBlock {
                  id
                  content {
                    html
                    text
                  }
                }
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
                ctaBoxes {
                  type
                  theme
                  tag
                  customBackgroundColor
                  heading {
                    html
                  }
                  description {
                    html
                  }
                  primaryButtonText
                  primaryButtonLink
                  secondaryButtonText
                  secondaryButtonLink
                }
              }

              peopleList(first: 500, orderBy: order_ASC) {
                order
                name
                title
                position
                linkedInUrl
                twitterUrl
                picture {
                  url(
                    transformation: { document: { output: { format: webp } } }
                  )
                }
              }
            }
          }
        `}
        render={data => (
          <div className="about-page-new">
            {data.SuperOps.pages.map(page => {
              const { title, pageBlock, navigationBlock, seo, ctaBoxes } = page
              return (
                <>
                  <Frame seo={seo}>
                    <header>
                      <Navigation
                        links={navigationBlock}
                        page={title}
                        color={"#fff6f6"}
                      />
                    </header>

                    <main>
                      <section className="hero">
                        <Container>
                          <Slide delay="200">
                            <div className="intro">
                              <div className="quote p14">
                                {parse(pageBlock[4].content.html)}
                              </div>
                              <div className="title">
                                {parse(pageBlock[5].content.html)}
                              </div>
                              <div className="subtitle p18">
                                {parse(pageBlock[6].content.html)}
                              </div>
                            </div>
                          </Slide>
                          <Slide delay="200">
                            {/* <StaticImage
                              placeholder="blurred"
                              className="red-line"
                              src={"../assets/images/red_line_one.svg"}
                            /> */}
                            <div className="red-line">
                              <svg
                                width="156"
                                height="15"
                                viewBox="0 0 156 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M154.456 6.79447C123.851 1.68218 92.9272 5.55976 62.3493 5.18929C42.1295 4.93617 21.8867 3.70209 1.68443 3.58344C1.07878 3.58175 0.602622 4.58111 0.621489 5.82436C0.640721 7.0676 1.14776 8.07542 1.75305 8.07712C21.9531 8.19581 42.1937 9.42995 62.4114 9.68311C92.9344 10.0548 123.799 6.17098 154.349 11.277C154.955 11.3761 155.469 10.4583 155.5 9.22145C155.528 7.98467 155.058 6.90114 154.456 6.79447Z"
                                  fill="#FF0055"
                                />
                              </svg>
                            </div>
                          </Slide>
                          <Slide delay="200">
                            <div className="message p16">
                              {parse(pageBlock[7].content.html)}
                            </div>
                          </Slide>
                        </Container>
                      </section>

                      <section className="superpower position-relative">
                        <Slide delay="200">
                          <Container className="br10">
                            <div className="p16 wrapper">
                              {parse(pageBlock[8].content.html)}
                            </div>

                            <div className="founder-section d-flex justify-content-center">
                              <FoundersCard
                                data={data.SuperOps.peopleList.filter(
                                  da => da.position === "Founder"
                                )}
                              />
                            </div>
                          </Container>
                        </Slide>
                      </section>

                      <section className="whorv">
                        <Slide delay="200">
                          <Container className="br10">
                            <div className="p16 content">
                              {parse(pageBlock[9].content.html)}
                            </div>
                            <div className="details">
                              {/* {pageBlock[16].content.text
                                .split(" ")
                                .map((txt, i) => {
                                  return (
                                    <Slide delay="200">
                                      <div className="details-aboutus-card">
                                        <h3>{txt}</h3>
                                        <span>
                                          {i === 0
                                            ? "founded"
                                            : i === 1
                                            ? "employees"
                                            : "funding"}
                                        </span>
                                      </div>
                                    </Slide>
                                  )
                                })} */}

                              <Slide delay="200">
                                <div className="details-aboutus-card">
                                  <h3>2020</h3>
                                  <span>founded</span>
                                </div>
                              </Slide>

                              <Slide delay="200">
                                <div className="details-aboutus-card">
                                  <h3>50+</h3>
                                  <span>employees</span>
                                </div>
                              </Slide>

                              <Slide delay="200">
                                <div className="details-aboutus-card">
                                  <h3>$ 17M</h3>
                                  <span>funding</span>
                                </div>
                              </Slide>
                            </div>
                          </Container>
                        </Slide>
                      </section>

                      <section className="investors-section mb-0">
                        <Container className="br10">
                          <div className="title position-relative">
                            <Slide delay="200">
                              {parse(pageBlock[11].content.html)}
                              <div className="position-absolute red-line">
                                <svg
                                  width="156"
                                  height="15"
                                  viewBox="0 0 156 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M154.456 6.79447C123.851 1.68218 92.9272 5.55976 62.3493 5.18929C42.1295 4.93617 21.8867 3.70209 1.68443 3.58344C1.07878 3.58175 0.602622 4.58111 0.621489 5.82436C0.640721 7.0676 1.14776 8.07542 1.75305 8.07712C21.9531 8.19581 42.1937 9.42995 62.4114 9.68311C92.9344 10.0548 123.799 6.17098 154.349 11.277C154.955 11.3761 155.469 10.4583 155.5 9.22145C155.528 7.98467 155.058 6.90114 154.456 6.79447Z"
                                    fill="#FF0055"
                                  />
                                </svg>
                              </div>
                            </Slide>
                          </div>
                          <div className="investors">
                            <SeedInvestors
                              data={data.SuperOps.peopleList.filter(
                                da => da.position === "Seed Investor"
                              )}
                            />
                          </div>
                          <div>
                            <section className="angel-investors-section ">
                              <AngelInvestors
                                data={data.SuperOps.peopleList.filter(
                                  da => da.position === "Angel Investor"
                                )}
                              />
                            </section>
                          </div>
                        </Container>
                      </section>

                      <section className="career-cta ">
                        <Slide delay="200">
                          <Container>
                            <Row>
                              <Col lg={6}>
                                <CTA
                                  data={[ctaBoxes[0]]}
                                  lgLeft={11}
                                  lgRight={11}
                                />
                              </Col>
                              <Col lg={6}>
                                <CTA
                                  data={[ctaBoxes[1]]}
                                  lgLeft={11}
                                  lgRight={11}
                                  className="cta-right"
                                />
                              </Col>
                            </Row>
                          </Container>
                        </Slide>
                      </section>
                    </main>
                  </Frame>
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default AboutUs
