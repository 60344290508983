import React from "react"
import LazyLoad from "react-lazyload"
import Slide from "../utility/slide_content"
import {Row, Col} from "react-bootstrap"

const seedInvestors = ({ data }) => {
  return (
    <>
      <div className="investors-column">
        <Row>
          {data.map(people => {
            const { picture } = people
            return (
              <Col lg={3} xs={6} sm={6}>
                <div className="investors-card">
                  <Slide delay="250">
                    <div className="about-seed-investors-block">
                      <LazyLoad throttle={200} height={200}>
                        <img
                          src={picture.url}
                          className="about-seed-investors-img objcvr"
                          alt={`Seed Investors`}
                          width="8"
                          height="8"
                        />
                      </LazyLoad>
                    </div>
                  </Slide>
                </div>
              </Col>
            )
          })}
        </Row>
      </div>
    </>
  )
}

export default seedInvestors
